import React, { useEffect, useState } from "react";
import Exchanger from "./components/Exchanger.js";
import Calculator from "./components/Calculator.js";
import "./components/styles/stileUI.css";

export default function App() {
  // Exchanger state
  const [money, setMoney] = useState("");
  const [result, setResult] = useState("");
  // Calculator state
  const [input, setInput] = useState("");
  const [answer, setAnswer] = useState("");
  // Description Box
  const [description, setDescription] = useState(null);

  function toCalculator() {
    setInput("");
    setAnswer("");
    setInput(result);
  }
  function fromCalculator() {
    setMoney("");
    if (input) setMoney(input);
    if (answer) setMoney(answer);
  }

  function descriptionSetter(description) {
    setDescription(description);
  }

  let hoverTime;

  function onHoverHold(description) {
    hoverTime = setTimeout(() => descriptionSetter(description), 1000);
  }

  function clearDescription() {
    clearTimeout(hoverTime);
    setDescription(null);
  }

  useEffect(function () {
    const timer = setTimeout(() => {
      const btnActivate = document.querySelector(".btn-start");
      const mainPage = document.querySelector(".firstPageToLeft");
      const pageCalculator = document.querySelector(".appPageToRight");
      if (!btnActivate) return;

      function handleActivateApp() {
        mainPage.classList.add("moveLeft");
        pageCalculator.classList.add("positionOnScreen");
        console.log("activated");
      }

      btnActivate.addEventListener("click", handleActivateApp);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container-app">
      <div className="firstPageToLeft">
        <FirstPageMozuk />
      </div>
      <div className="appPageToRight">
        <div className="centralizer">
          <div className="box">
            <Heading>Exchange .elkata</Heading>
            <Exchanger
              money={money}
              setMoney={setMoney}
              result={result}
              setResult={setResult}
              onUpClick={toCalculator}
              onDownClick={fromCalculator}
              onButtonHover={onHoverHold}
              onUnhoverButton={clearDescription}
            />
            {description && (
              <DescriptionElement>{description}</DescriptionElement>
            )}
            <Calculator
              input={input}
              setInput={setInput}
              answer={answer}
              setAnswer={setAnswer}
              onAcClick={setMoney}
              onButtonHover={onHoverHold}
              onUnhoverButton={clearDescription}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function FirstPageMozuk() {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch("/firstPageComponent/pageFirst.html")
      .then((res) => res.text())
      .then((html) => setHtmlContent(html));
  }, []);
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

function Heading({ children }) {
  return <h1 styles={{ textAlign: "center" }}>{children}</h1>;
}

function DescriptionElement({ children }) {
  return <div className="info-element">{children}</div>;
}
