import { useState } from "react";
import { useEffect } from "react";
import "./styles/exchangeStyle.css";

export default function Exchanger({
  money,
  setMoney,
  result,
  setResult,
  onUpClick,
  onDownClick,
  onButtonHover,
  onUnhoverButton,
}) {
  const [cur1, setCur1] = useState("BGN");
  const [cur2, setCur2] = useState("EUR");

  const outputMoney = Number(result).toFixed(2);

  function switchCur() {
    const cur1Store = cur1;
    const cur2Store = cur2;
    setCur1(cur2Store);
    setCur2(cur1Store);
  }

  useEffect(
    function () {
      if (money === "") {
        setResult(""); // Clear the result when the input is empty
        return;
      }
      if (cur1 === cur2) return;
      const kris = async function () {
        const res = await fetch(
          `https://api.frankfurter.app/latest?amount=${money}&from=${cur1}&to=${cur2}`
        );
        const data = await res.json();
        setResult(Object.values(data?.rates));
      };
      kris();
    },
    [cur1, cur2, money, setResult]
  );

  return (
    <div className="calculator">
      <input
        type="text"
        placeholder="Enter amount here"
        value={money}
        onMouseEnter={() => onButtonHover("Enter value in the box")}
        onMouseLeave={onUnhoverButton}
        onChange={(e) => {
          if (e.target.value === "" || Number.isFinite(+e.target.value)) {
            setMoney(e.target.value);
            console.log(e.target.value);
          }
        }}
      />

      <select value={cur1} onChange={(e) => setCur1(e.target.value)}>
        <option value="BGN">BGN</option>
        <option value="USD">USD</option>
        <option value="EUR">EUR</option>
        <option value="CAD">CAD</option>
        <option value="INR">INR</option>
      </select>
      <button
        className="switch-button"
        onClick={switchCur}
        onMouseEnter={() => onButtonHover("Use to switch the two currencies")}
        onMouseLeave={onUnhoverButton}
      >
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(90)"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0" />

          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          />

          <g id="SVGRepo_iconCarrier">
            {" "}
            <g id="Arrow / Arrow_Reload_02">
              {" "}
              <path
                id="Vector"
                d="M14 16H19V21M10 8H5V3M19.4176 9.0034C18.8569 7.61566 17.9181 6.41304 16.708 5.53223C15.4979 4.65141 14.0652 4.12752 12.5723 4.02051C11.0794 3.9135 9.58606 4.2274 8.2627 4.92661C6.93933 5.62582 5.83882 6.68254 5.08594 7.97612M4.58203 14.9971C5.14272 16.3848 6.08146 17.5874 7.29157 18.4682C8.50169 19.3491 9.93588 19.8723 11.4288 19.9793C12.9217 20.0863 14.4138 19.7725 15.7371 19.0732C17.0605 18.374 18.1603 17.3175 18.9131 16.0239"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />{" "}
            </g>{" "}
          </g>
        </svg>
      </button>
      <select value={cur2} onChange={(e) => setCur2(e.target.value)}>
        <option value="BGN">BGN</option>
        <option value="USD">USD</option>
        <option value="EUR">EUR</option>
        <option value="CAD">CAD</option>
        <option value="INR">INR</option>
      </select>
      <p>
        {cur1 === cur2 ? (
          <>Choose different currency </>
        ) : (
          `${cur2} ${outputMoney}`
        )}
      </p>
      <div className="button-box-up-down">
        <button
          onClick={onUpClick}
          onMouseEnter={() =>
            onButtonHover(
              "The component is used to send the exchanged amaunt value to the calculator input"
            )
          }
          onMouseLeave={onUnhoverButton}
          className="left-btn"
        >
          <svg
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
              fill="#0F0F0F"
            />
          </svg>
        </button>
        <div className="devider"></div>
        <button
          onClick={onDownClick}
          onMouseEnter={() =>
            onButtonHover(
              "The component is used to send the calculated number to the exchanger input"
            )
          }
          onMouseLeave={onUnhoverButton}
          className="right-btn"
        >
          <svg
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z"
              fill="#0F0F0F"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
